.popover {
	position: absolute;
	white-space: nowrap;
	background-color: #fff;
	border-radius: 0.25rem;
	z-index: 1000;
	top: 100%;
	border: 1px solid rgba(0, 0, 0, 0.15);
	right: 0;
	left: auto;
}
