.bgcYoutube {
	background-color: #ed5565 !important;
	color: #fff !important;
	border: 0 !important;
}
.btnLg {
	padding: 15px;
	padding-right: 18px;
	font-size: 15pt !important;
}
