.background {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f5f5f5;
	height: 100vh;
}

.body {
	max-width: 350px;
	padding: 5px;
	margin: auto;
}
