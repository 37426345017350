.nav {
	background-color: #057ecf !important;
	color: white !important;
}

.active {
	font-weight: bold;
}

.btn {
	color: rgba(255, 255, 255, 0.8) !important;
}
