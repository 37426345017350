.details {
	display: flex;
}
.details > div {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 4px;
	padding: 3px;
}
.bold {
	font-weight: bold;
}
a:link {
	text-decoration: none;
}
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
.singleLine {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
