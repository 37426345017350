.weblinkRow {
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
	flex-wrap: wrap;
	padding: 0.25rem;
}

.bgcNaver {
	background-color: #00c63c !important;
	color: #fff !important;
}
.bgcDaum {
	background-color: #f05447 !important;
	color: #fff !important;
}

.logoBtn {
	display: inline;
	padding: 0.5rem 0.875rem;
	font-size: 1rem !important;
	user-select: none;
	text-decoration: none;
}

img {
	max-width: 100%;
	max-height: 500px;
}
